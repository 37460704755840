import { TableCell, type SxProps } from '@mui/material'
import type { ProtectionStatusChangeSeversideData } from '@orus.eu/backend/src/views/subscriptions/contract-model'
import { Chip } from '@orus.eu/pharaoh'
import { getProtectionStatusAtTime, type ProtectionStatusChange } from '@orus.eu/protection'
import { useParams } from '@tanstack/react-router'
import { getContractStatusUiProps } from '../../lib/contract-util'
import { formatDateTime } from '../../lib/format'
import { oneMinute, useCurrentTimestamp } from '../../lib/use-current-timestamp'
import { usePermissions } from '../../lib/use-permissions'
import { TableCellHeader } from '../molecules/table-elements/table-cell-header'
import { TableRowLink } from '../molecules/table-elements/table-row-link'
import { BackofficeTable } from './backoffice-table'
import { ChipOrganizations } from './chip-organisations'

type BackofficeContractsTableProps = {
  contracts: ContractSummary[]
  sx?: SxProps
}

export function BackofficeContractsTable({ contracts, sx = {} }: BackofficeContractsTableProps): JSX.Element {
  return (
    <BackofficeTable
      columns={
        <>
          <col
            style={{
              width:
                '400px' /* in production, values in this column vary widely, so we need a fixed size to preserve overall layout of the table*/,
            }}
          />
          <col />
          <col />
          <col />
          <col />
        </>
      }
      headers={
        <>
          <TableCellHeader>Contract</TableCellHeader>
          <TableCellHeader>Date d’effet</TableCellHeader>
          <TableCellHeader>Statut</TableCellHeader>
          <TableCellHeader>Apporteur d’affaires</TableCellHeader>
        </>
      }
      rows={contracts.map((contract) => (
        <ContractsListRow key={contract.subscriptionId} contract={contract} />
      ))}
      sx={sx}
    />
  )
}

type ContractsListRowProps = {
  contract: ContractSummary
}

type ContractSummary = {
  subscriptionId: string
  protectionHistory: ProtectionStatusChange<ProtectionStatusChangeSeversideData>[]
  companyName: string
  assetName: string
  startTimestamp: number
  organization: string | null
}

function ContractsListRow({ contract }: ContractsListRowProps): JSX.Element {
  const currentTimestamp = useCurrentTimestamp(oneMinute)
  const protectionStatusSchedule = getProtectionStatusAtTime(contract.protectionHistory, currentTimestamp)
  const { icon, label, variant } = getContractStatusUiProps(protectionStatusSchedule)

  const { organization } = useParams({ strict: false })
  const isPlatform = usePermissions().type === 'platform'

  return (
    <TableRowLink
      href={
        !isPlatform && !!organization
          ? {
              to: '/partner/$organization/contracts/$subscriptionId',
              params: { organization: organization, subscriptionId: contract.subscriptionId },
            }
          : { to: '/bak/contracts/$subscriptionId', params: { subscriptionId: contract.subscriptionId } }
      }
      data-testid={`contract-list-row-${contract.companyName}`}
    >
      <TableCell>{contract.assetName}</TableCell>
      <TableCell>{formatDateTime(contract.startTimestamp)}</TableCell>
      <TableCell>
        <Chip size="large" icon={icon} variant={variant}>
          {label}
        </Chip>
      </TableCell>
      <TableCell>
        <ChipOrganizations organizations={[contract.organization]} />
      </TableCell>
    </TableRowLink>
  )
}
