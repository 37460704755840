import { ensureError } from '@orus.eu/error'
import { useCrash } from '@orus.eu/pharaoh'
import { useEffect, useState } from 'react'

export type UpToDateStatus = 'checking' | 'up-to-date' | 'out-of-date'

/**
 * Uses an asyncrhonous API that retrieves an up-to-date status to get an immediately useable
 * status for the UI.
 *
 * @param statusApi
 * @returns
 */
export function useUpToDateStatus(statusApi: () => Promise<boolean>): UpToDateStatus {
  const crash = useCrash()
  const [state, setState] = useState<UpToDateStatus>('checking')
  useEffect(() => {
    let timeoutId: number | null = null
    const check = () => {
      statusApi().then(
        (upToDate) => {
          if (upToDate) {
            setState('up-to-date')
          } else {
            timeoutId = window.setTimeout(check, 5000)
            setState('out-of-date')
          }
        },
        (err: unknown) => {
          crash(ensureError(err))
        },
      )
    }
    check()
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [crash, statusApi])

  return state
}
